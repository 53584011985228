<template>
  <form
    class="NewBuildingForm"
    @submit.prevent="onSubmit">
    <span
      v-if="!buildingToEdit"
      class="Title">{{ mixWB('ADD_NEW_BUILDING') }}</span>
    <span
      v-else
      class="Title">{{ mixWB('EDIT_BUILDING') }}</span>

    <InputField
      :value="data.title"
      :label="`${
        mixWB('NAME_OF_BUILDING')
      } (${
        mixWB('REQUIRED').toLowerCase()
      })`"
      name="title"
      formGroupName="new-building-form"
      isRequired
      @on-update="onValueUpdate"/>

    <InputField
      :value="data.cadastralNumber"
      :label="mixWB('CADASTRAL_NUMBER')"
      name="cadastralNumber"
      @on-update="onValueUpdate" />

    <InputField
      :value="data.landOwnerAssosiationName"
      :label="mixWB('LAND_OWNER_ASSCOSIATION')"
      name="landOwnerAssosiationName"
      @on-update="onValueUpdate" />

    <InputField
      :value="data.yearBuilt"
      :label="mixWB('YEAR_BUILT')"
      type="number"
      name="yearBuilt"
      @on-update="onValueUpdate" />

    <InputField
      :value="data.remodelingYear"
      :label="mixWB('REMODELING_YEAR')"
      formGroupName="new-building-form"
      type="number"
      name="remodelingYear"
      @on-update="onValueUpdate" />

    <InputField
      :value="data.builtArea"
      :label="mixWB('BUILT_AREA')"
      :unit="mixWB('M2')"
      formGroupName="new-building-form"
      type="number"
      name="builtArea"
      @on-update="onValueUpdate" />

    <InputField
      :value="data.totalLivingArea"
      :label="mixWB('TOTAL_LIVING_AREA')"
      :unit="mixWB('M2')"
      formGroupName="new-building-form"
      type="number"
      name="totalLivingArea"
      @on-update="onValueUpdate" />

    <InputField
      :value="data.totalBusinessArea"
      :label="mixWB('TOTAL_BUSINESS_AREA')"
      :unit="mixWB('M2')"
      formGroupName="new-building-form"
      type="number"
      name="totalBusinessArea"
      @on-update="onValueUpdate" />

    <InputField
      :value="data.basementArea"
      :label="mixWB('BASEMENT_AREA')"
      :unit="mixWB('M2')"
      formGroupName="new-building-form"
      type="number"
      name="basementArea"
      @on-update="onValueUpdate" />

    <InputField
      :value="data.utilizedAtticArea"
      :label="mixWB('UTILIZED_ATTIC_AREA')"
      :unit="mixWB('M2')"
      formGroupName="new-building-form"
      type="number"
      name="utilizedAtticArea"
      @on-update="onValueUpdate" />

    <Button
      :text="getButtonText()"
      type="submit" />
  </form>
</template>

<script>
import InputField from '@/components/FormElements/InputField.vue'
import Button from '@/components/Buttons/Button.vue'
import EventBus from '@/EventBus'
import { mapGetters } from 'vuex'
import ownerAssociations from '@/globals/json/owner-associations.json'

export default {
  name: 'NewBuildingForm',
  props: {
    buildingToEdit: {
      type: [Object, Boolean],
      required: false,
      default: false,
    },
  },
  data() {
    return {
      ownerAssociations,
      data: {
        title: '',
        cadastralNumber: '',
        landOwnerAssosiationName: '',
        yearBuilt: 0,
        remodelingYear: 0,
        builtArea: 0,
        totalLivingArea: 0,
        totalBusinessArea: 0,
        basementArea: 0,
        utilizedAtticArea: 0,
      },
    }
  },
  computed: {
    ...mapGetters([
      'screeningUnits',
      'isDemoUser',
    ]),
  },
  methods: {
    getButtonText() {
      if (!this.buildingToEdit) {
        return this.mixWB('CREATE_BUILDING')
      }

      return this.mixWB('SAVE_CHANGES')
    },
    onValueUpdate({ name, value }) {
      this.data[name] = value
    },
    onSubmit() {
      // Validation
      EventBus.$emit('validate-field', 'new-building-form')
      if (!this.data.title) {
        return
      }

      if (!this.buildingToEdit) {
        this.$emit('pass-to-parent', { name: 'form-submit', value: this.data })
        return
      }
      this.$emit('pass-to-parent', { name: 'form-submit-edit', value: this.data })
    },
    setEditValuesOnLoad() {
      if (!this.buildingToEdit) {
        return
      }

      Object.keys(this.data).forEach((key) => {
        if (this.buildingToEdit.manualData[key]) {
          this.data[key] = this.buildingToEdit.manualData[key]
        }
      })
    },
    getMissingValuesOnLoad() {
      if (this.buildingToEdit) {
        return
      }

      const building = this.screeningUnits.buildings.find((x) => x.bbrID)

      if (!building) {
        return
      }

      if (this.isDemoUser) {
        return
      }

      if (!this.data.cadastralNumber) {
        this.data.cadastralNumber = building.bbrData.MatrNr.toString() || ''
      }

      if (!this.data.landOwnerAssosiationName) {
        if (building.bbrData.Landsejerlavkode) {
          const text = ownerAssociations[building.bbrData.Landsejerlavkode]
          this.data.landOwnerAssosiationName = text || ''
        }
      }
    },
  },
  components: {
    InputField,
    Button,
  },
  created() {
    this.setEditValuesOnLoad()
    this.getMissingValuesOnLoad()
  },

}
</script>

<style lang="stylus" scoped>
  .NewBuildingForm
    display block
    span
      display block

  .Title
    modalTitle()

</style>
