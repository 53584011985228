<template>
  <div class="CadastralMap">
    <div
      id="map"
      class="Map"/>
    <div class="Bottom">
      <div class="MapText">
        <span>{{ mixWB('MAP_ZOOM_INFO_TEXT') }}</span>
        <span>{{ mixWB('MAP_DRAG_INFO_TEXT') }}</span>
      </div>
      <div
        class="ButtonWrap"
        v-if="hasExistingImageSaved">
        <Button

          :text="mixWB('TAKE_PICTURE_AGAIN')"
          :fullWidth="false"
          :isLoading="cadastralMapUploadStatus !== 'uploaded'"
          @button-click="onTakeImageAgainClick" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable prefer-destructuring */
import * as Sentry from '@sentry/browser'

// Open Layer imports
import Button from '@/components/Buttons/Button.vue'
import 'ol/ol.css'
import {
  Circle as CircleStyle, Fill, Stroke, Style, Text,
} from 'ol/style'
import { DragPan, MouseWheelZoom, defaults } from 'ol/interaction'
import { Feature, Map, View } from 'ol'
import { get } from 'ol/proj'
import { Group, Tile } from 'ol/layer'
import { platformModifierKeyOnly } from 'ol/events/condition'
import { register } from 'ol/proj/proj4'
import { WMTS, TileWMS } from 'ol/source'
import Point from 'ol/geom/Point'
import proj4 from 'proj4'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import WMTSTileGrid from 'ol/tilegrid/WMTS'
import { mapGetters } from 'vuex'

export default {
  name: 'CadastralMap',
  props: {
    addressPoint: {
      type: Array,
      required: true,
    },
    buildingPoints: {
      type: Array,
      required: true,
    },
    exportMapAsImage: {
      type: Boolean,
      required: false,
      default: false,
    },
    cadastralMapUploadStatus: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      dtfToken: 'b0d452db16a2c05ea1542260ecada19a',
      map: {},
    }
  },
  computed: {
    ...mapGetters([
      'addressImages',
    ]),
    hasExistingImageSaved() {
      if (!this.exportAsImage) {
        return false
      }

      return this.addressImages?.overview?.cadastralMap?.length
    },
  },
  methods: {
    onTakeImageAgainClick() {
      this.exportAsImage()
    },
    exportAsImage() {
      const mapCanvas = document.createElement('canvas')
      const size = this.map.getSize()
      mapCanvas.width = size[0]
      mapCanvas.height = size[1]
      const mapContext = mapCanvas.getContext('2d')

      Array.prototype.forEach.call(
        document.querySelectorAll('.ol-layer canvas'),
        (canvas) => {
          if (canvas.width > 0) {
            const { opacity } = canvas.parentNode.style
            mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity)
            const { transform } = canvas.style
            // Get the transform parameters from the style's transform matrix
            const matrix = transform
              .match(/^matrix\(([^(]*)\)$/)[1]
              .split(',')
              .map(Number)
            // Apply the transform to the export map context
            CanvasRenderingContext2D.prototype.setTransform.apply(
              mapContext,
              matrix,
            )
            mapContext.drawImage(canvas, 0, 0)
          }
        },
      )

      const base64URL = mapCanvas.toDataURL('image/jpeg')

      if (!base64URL) {
        Sentry.captureMessage('Unable to convert cadastral map to image')
        return
      }

      this.$emit('image-exported', { base64URL })
    },
    createFeatureStyle(type, name) {
      // Address
      if (type === 'address') {
        return new Style({
          image: new CircleStyle({
            radius: 6,
            fill: new Fill({ color: 'red' }),
            stroke: new Stroke({ color: 'yellow', width: 1 }),
          }),
        })
      }

      // Building
      return new Style({
        text: new Text({
          text: name,
          overflow: true,
          fill: new Fill({
            color: 'green',
          }),
          stroke: new Stroke({
            color: 'yellow',
            width: 3,
          }),
        }),
      })
    },
  },
  components: {
    Button,
  },
  mounted() {
    // Set projection as we are not using the default OpenLayers projections
    // You can define it yourself or you can use the proj4 library as done below
    proj4.defs('EPSG:25832', '+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs')
    register(proj4)
    const myProjection = get('EPSG:25832')
    const extent = [120000, 5661139.2, 1378291.2, 6500000]
    myProjection.setExtent(extent)

    // Set the WMTS tile grid. We do this on an overall basis as all the
    // Kortforsyningen WMTS are based on the same tile grid
    const myTileGrid = new WMTSTileGrid({
      extent,
      resolutions: [
        1638.4, 819.2, 409.6, 204.8, 102.4, 51.2, 25.6, 12.8, 6.4, 3.2, 1.6, 0.8, 0.4, 0.2,
      ],
      matrixIds: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13'],
    })

    const features = []

    // Add building points
    const feature = new Feature({
      geometry: new Point(this.addressPoint),
      type: 'address',
    })
    features.push(feature)

    // Add building points
    this.buildingPoints.forEach((point) => {
      features.push(
        new Feature({
          geometry: new Point(point.point),
          type: 'building',
          name: point.name,
        }),
      )
    })

    const vectorSource = new VectorSource({
      features,
      wrapX: false,
    })
    const vector = new VectorLayer({
      source: vectorSource,
      style: (myFeature) => this.createFeatureStyle(myFeature.get('type'), myFeature.get('name')),
    })

    const map = new Map({
      target: 'map',
      interactions: defaults({ dragPan: false, mouseWheelZoom: false }).extend([
        new DragPan({
          condition(event) {
            return this.getPointerCount() === 2 || platformModifierKeyOnly(event)
          },
        }),
        new MouseWheelZoom({
          condition(event) {
            return platformModifierKeyOnly(event)
          },
        }),
      ]),
      layers: [
        new Group({
          title: 'Base maps', // This title of the group is shown in the layer switcher
          layers: [
            // Ortofoto [WMTS:orto_foraar]
            new Tile({
              title: 'Ortofoto', // This is the layer title shown in the layer switcher
              type: 'base', // use 'base' for base layers, otherwise 'overlay'
              visible: true,
              opacity: 1.0,
              source: new WMTS({
                url: `https://api.dataforsyningen.dk/orto_foraar_wmts_DAF?service=WMTS&request=GetCapabilities&token=${ this.dtfToken }`,
                layer: 'orto_foraar_wmts',
                matrixSet: 'KortforsyningTilingDK',
                format: 'image/jpeg',
                visible: 'true',
                tileGrid: myTileGrid,
                style: 'default',
                size: [256, 256],
                crossOrigin: 'anonymous',
              }),
            }),
          ],
        }),
        new Group({
          title: 'Overlays',
          layers: [
            // Matrikelskel overlay [WMS:mat]
            new Tile({
              title: 'Matrikel',
              type: 'overlay',
              visible: true,
              opacity: 1.0,
              zIndex: 1000,
              source: new TileWMS({
                url: `https://api.dataforsyningen.dk/wms/MatGaeldendeOgForeloebigWMS_DAF?service=WMS&request=GetCapabilities&token=${ this.dtfToken }`,
                params: {
                  LAYERS: 'MatrikelSkel_Gaeldende,Centroide_Gaeldende',
                  VERSION: '1.1.1',
                  TRANSPARENT: 'TRUE',
                  FORMAT: 'image/png',
                  STYLES: 'Gule_skel',
                },
                crossOrigin: 'anonymous',
              }),
            }),
          ],
        }),
      ],
      // turn off the default attribution control as we will create a new one later on
      // make the view
      view: new View({
        center: this.addressPoint, // start center position
        zoom: 13, // start zoom level
        resolutions: myTileGrid.getResolutions(), // use the resolutions from the tile grid
        projection: myProjection, // use our custom projection defined earlier
      }),
    })

    map.addLayer(vector)

    this.map = map

    map.once('rendercomplete', () => {
      if (this.exportMapAsImage) {
        this.exportAsImage()
      }
    })
  },
}
</script>

<style lang="stylus" scoped>
  .CadastralMap
    box(100%)

  .Map
    box(100%)
    margin-bottom 5px

  .Bottom
    display flex
    justify-content space-between
    align-items center
    .MapText
      flex-shrink 0
      span
        display block
        font-size 0.875rem
        color $color_grey_light
      +below($phablet)
        display none
    .ButtonWrap
      max-width calc(50% - 10px)

</style>
