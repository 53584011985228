<template>
  <div class="BuildingSelectItem">
    <!-- Building -->
    <div
      class="BuildingPart"
      @click="onBuildingClick">
      <div class="CheckboxWrap">
        <div
          :class="{
            'IsSelected': building.isSelected,
            'IsDisabled': isUnitInUse(building),
          }"
          class="CheckBox">
          <CheckIcon class="CheckIcon" />
        </div>
      </div>
      <div class="InfoWrap">
        <span class="BuildingTitle">{{ building.getTitle() }}</span>
        <span>{{ building.getBuildingTypeCodeText() }}</span>
        <span>
          {{ mixWB('BUILT_AREA') }}:
          {{ building.getBuiltArea() ? `${ building.getBuiltArea() }` : '?' }}
        </span>
        <span>
          {{ mixWB('YEAR_BUILT') }}:
          {{ building.getYearBuilt() ? building.getYearBuilt() : '?' }}
        </span>
        <span v-if="building.getRemodelingYear()">
          {{ mixWB('REMODELING_YEAR') }}: {{ building.getRemodelingYear() }}
        </span>
        <span v-if="building.getStatusNumericValue() > 1">
          {{ mixWB('STATUS') }}: {{ building.getStatus() }}
        </span>
      </div>
      <div
        class="MenuWrap"
        @click.stop>
        <DotMenu
          :use-component="BuildingSelectMenu"
          :dataToComponent="{
            canBeDeleted: isUnitAllowedToBeDeleted(building),
            canBeEdited: !building.bbrID
          }"
          position="normal"
          @menu-click="onMenuClick" />
      </div>
    </div>

    <!-- Floors -->
    <div
      v-if="buildingFloors.length"
      class="FloorList">
      <FloorSelectItem
        v-for="floor in buildingFloors"
        :key="floor.id"
        :floor="floor"
        :isUnitAllowedToBeDeleted="isUnitAllowedToBeDeleted"
        :isUnitInUse="isUnitInUse"
        :apartments="apartments" />
    </div>
  </div>
</template>

<script>
import CheckIcon from '@/assets/svg/check.svg?inline'
import FloorSelectItem from '@/components/ScreeningItems/FloorSelectItem.vue'
import DotMenu from '@/components/DotMenu/DotMenu.vue'
import BuildingSelectMenu from '@/components/ScreeningItems/Menus/BuildingSelectMenu.vue'
import EventBus from '@/EventBus'
import { sortBy } from 'lodash-es'

export default {
  name: 'BuildingSelectItem',
  props: {
    building: {
      type: Object,
      required: true,
    },
    floors: {
      type: Array,
      required: true,
    },
    apartments: {
      type: Array,
      required: true,
    },
    isUnitAllowedToBeDeleted: {
      type: Function,
      required: true,
    },
    isUnitInUse: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      BuildingSelectMenu,
    }
  },
  computed: {
    buildingFloors() {
      return sortBy(this.floors.filter(
        (x) => x.data.buildingID === this.building.id,
      ), (floor) => floor.data.numericFloorValue)
    },
  },
  methods: {
    onBuildingClick() {
      if (this.building.isSelected && this.isUnitInUse(this.building)) {
        return
      }
      EventBus.$emit('building-click', this.building.id)
    },
    onMenuClick(value) {
      if (value === 'edit-building') {
        EventBus.$emit('building-edit-click', this.building.id)
      }
      if (value === 'delete-building') {
        EventBus.$emit('building-delete-click', this.building.id)
      }
      if (value === 'create-floor') {
        EventBus.$emit('building-create-floor-click', this.building.id)
      }
    },
  },
  components: {
    CheckIcon,
    FloorSelectItem,
    DotMenu,
  },
}
</script>

<style lang="stylus" scoped>
  .BuildingSelectItem
    background-color $color_grey_lightestest
    border-bottom 1px solid $color_grey_lighter
    &:first-child
      border-top 1px solid $color_grey_lighter
    +below($wide)
      margin-left -20px
      margin-right -20px
    +below($tablet)
      margin-left -15px
      margin-right -15px

  .BuildingPart
    display flex
    cursor pointer
    .CheckboxWrap
      flex-center-children()
      padding-left 15px
      .CheckBox
        box(26px)
        padding 2px
        margin-right 15px
        background-color #fff
        border 1px solid #777
        .CheckIcon
          display none
        &.IsSelected
          border 1px solid $color_primary
          .CheckIcon
            display block
            fill $color_primary
        &.IsDisabled
          border 1px solid $color_grey_light
          .CheckIcon
            fill $color_grey
    .InfoWrap
      flex-grow 2
      padding 15px 10px 15px 0px
      span
        display block
        font-size 0.875rem
        padding-bottom 1px
      .BuildingTitle
        font-size 1rem
        text-transform uppercase
        font-weight bold
    .MenuWrap
      width 50px
      flex-center-children()

  .FloorList
    margin-left 56px
    border-top 1px solid $color_grey_lighter
</style>
