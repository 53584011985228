<template>
  <div class="ScreeningsUpdateBuildings">
    <div class="InnerPage">
      <CloseButton />

      <div class="Top">
        <div class="NormalPageTitle">
          {{ mixWB('UNITS_INCLUDED_IN_THE_PROJECT') }}
        </div>

        <p>{{ mixWB('SELECT_WHAT_TO_BE_MAPPED_TEXT') }}</p>
        <p v-if="currentScreeningData.isStarted">
          {{ mixWB('ONLY_POSSIBLE_TO_ADD_UNITS_TEXT') }}
        </p>
      </div>

      <div
        class="TooOldWrap"
        v-if="screeningIsTooOld">
        <span>{{ mixWB('PAGE_CANNOT_BE_SHOWN_AS_SCREENING_IS_TOO_OLD_TEXT') }}</span>
      </div>

      <GetBBRData
        v-if="isLoadingDataFromBBR && !hasSaveUnitsError"
        @no-units-found="onAllUnitsSaved" />

      <ScreeningUnitSelection
        v-if="!isLoadingDataFromBBR && !hasSaveUnitsError"
        :loadSavedUnits="loadSavedUnitsCounter" />

      <div
        v-if="hasSaveUnitsError"
        class="ErrorBox">
        <div class="WarningIcon">
          <WarningIcon />
        </div>
        <span>{{ mixWB('SOMETHING_WENT_WRONG_CONTACT_SUPPORT_TEXT') }}</span>
      </div>

      <CadastralMapAutoUpload v-if="showCadastralMapSection" />
    </div>
  </div>
</template>

<script>
import CloseButton from '@/components/ScreeningItems/CloseButton.vue'
import GetBBRData from '@/components/ScreeningItems/GetBBRData.vue'
import ScreeningUnitSelection from '@/components/ScreeningItems/ScreeningUnitSelection.vue'
import CadastralMapAutoUpload from '@/components/ScreeningItems/CadastralMapAutoUpload.vue'
import { mapGetters } from 'vuex'
import EventBus from '@/EventBus'
import WarningIcon from '@/assets/svg/warning.svg?inline'

export default {
  name: 'ScreeningsUpdateBuildings',
  data() {
    return {
      screeningIsTooOld: false,
      isLoadingDataFromBBR: false,
      loadSavedUnitsCounter: 0,
      hasSaveUnitsError: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentScreeningData',
      'screeningUnits',
      'isDemoUser',
    ]),
    showCadastralMapSection() {
      if (!this.currentScreeningData.address.isBBRAddress) {
        return false
      }

      if (this.isLoadingDataFromBBR) {
        return false
      }

      if (this.isDemoUser) {
        return false
      }

      if (this.hasSaveUnitsError) {
        return false
      }

      return true
    },
  },
  methods: {
    onAllUnitsSaved() {
      this.isLoadingDataFromBBR = false
      requestAnimationFrame(() => {
        this.loadSavedUnitsCounter += 1
      })
    },
    onAllUnitsSaveError() {
      this.hasSaveUnitsError = true
    },
    checkVersion() {
      const allGood = this.mixCompareVersionsSameOrNewer({
        versionA: this.currentScreeningData.appVersion,
        versionB: '1.13.0',
      })

      this.screeningIsTooOld = !allGood
    },
  },
  components: {
    CloseButton,
    GetBBRData,
    ScreeningUnitSelection,
    CadastralMapAutoUpload,
    WarningIcon,
  },
  created() {
    EventBus.$on('all-units-saved', this.onAllUnitsSaved)
    EventBus.$on('all-units-save-error', this.onAllUnitsSaveError)
    this.checkVersion()

    if (this.screeningIsTooOld) {
      return
    }

    // Manual address
    if (!this.currentScreeningData.address.isBBRAddress) {
      this.showSavedUnits = true
      return
    }

    // BBR address
    if (this.currentScreeningData.hasSavedBBRUnits) {
      this.showSavedUnits = true
    }
    else {
      this.isLoadingDataFromBBR = true
    }
  },
  destroyed() {
    EventBus.$off('all-units-saved', this.onAllUnitsSaved)
    EventBus.$off('all-units-save-error', this.onAllUnitsSaveError)
  },
}
</script>

<style lang="stylus" scoped>
  .ScreeningsUpdateBuildings
    page-wrap-outer-1()

  .InnerPage
    page-wrap-inner-1()

  .Top
    margin-bottom 20px

  .TooOldWrap
    flex-center-children-column()
    justify-content center
    margin 0 auto
    box(300px)
    max-width 100%
    span
      text-align center

  .LoadingDataFromBBR
    box(300px)
    max-width 100%
    flex-center-children-column()
    justify-content center
    margin 0 auto
    .LoadingWrap
      box(60px)
      position relative
      margin-bottom 15px

  .ErrorBox
    box(300px)
    max-width 100%
    flex-center-children-column()
    justify-content center
    margin 0 auto
    .WarningIcon
      box(50px)
      margin-bottom 10px
      svg
        fill $color_warning
    span
      text-align center

</style>
