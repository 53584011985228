<template>
  <form
    class="NewFloorForm"
    @submit.prevent="onSubmit">
    <span
      v-if="!floorToEdit"
      class="Title">{{ mixWB('CREATE_NEW_FLOOR_ON_BUILDING', [this.building.getTitle()]) }}</span>
    <span
      v-else
      class="Title">{{ mixWB('EDIT_FLOOR') }}</span>

    <p>
      {{ mixWB('CREATE_FLOOR_USE_NUMBER_DESCRIPTION') }}
    </p>
    <InputField
      :label="mixWB('FLOOR_NUMBER')"
      :value="data.numericFloorValue"
      placeholder="-1, 0, 1, 2"
      formGroupName="new-floor-form"
      name="numericFloorValue"
      isRequired
      autoFocus
      @on-update="onValueUpdate" />

    <ErrorText
      v-if="this.data.floorAlreadyExistsMessage"
      :text="this.mixWB('THIS_FLOOR_ALREADY_EXISTS')" />

    <ErrorText
      v-if="this.data.showNumberError"
      :text="this.mixWB('FLOOR_NUMBER_NOT_VALID')" />

    <Button
      :text="getButtonText()"
      type="submit" />
  </form>
</template>

<script>
import InputField from '@/components/FormElements/InputField.vue'
import EventBus from '@/EventBus'
import ErrorText from '@/components/FormElements/ErrorText.vue'
import Button from '@/components/Buttons/Button.vue'

export default {
  name: 'NewFloorForm',
  props: {
    building: {
      type: Object,
      required: true,
    },
    floorToEdit: {
      type: [Object, Boolean],
      required: false,
      default: false,
    },
    floors: {
      type: Array,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      data: {
        numericFloorValue: '0',
        floorAlreadyExistsMessage: false,
        showNumberError: false,
      },
    }
  },
  methods: {
    getButtonText() {
      if (!this.floorToEdit) {
        return this.mixWB('CREATE_FLOOR')
      }

      return this.mixWB('SAVE_CHANGES')
    },
    onValueUpdate({ name, value }) {
      this.data.floorAlreadyExistsMessage = false
      this.data.showNumberError = false

      this.data[name] = value
    },
    onSubmit() {
      // Validation
      EventBus.$emit('validate-field', 'new-floor-form')
      if (!this.data.numericFloorValue) {
        return
      }

      const number = Number(this.data.numericFloorValue)
      if (Number.isNaN(number)) {
        this.data.showNumberError = true
        return
      }

      // Check if floor already exists.
      const existingFloor = this.floors.find(
        (floor) => floor.data.buildingID === this.building.id
          && floor.data.numericFloorValue === Number(this.data.numericFloorValue),
      )
      if (existingFloor && existingFloor !== this.floorToEdit) {
        this.data.floorAlreadyExistsMessage = true
        return
      }
      if (!this.floorToEdit) {
        this.$emit('pass-to-parent', { name: 'form-submit', value: this.data })
        return
      }

      this.$emit('pass-to-parent', { name: 'form-submit-edit', value: this.data })
    },
    setValuesOnLoad() {
      if (!this.floorToEdit) {
        const currentTopFloorNumber = this.floors.reduce(
          (maxFloorValue, floor) => (floor.data.buildingID === this.building.id
            ? Math.max(maxFloorValue, floor.data.numericFloorValue)
            : maxFloorValue),
          -1,
        )

        this.data.numericFloorValue = (currentTopFloorNumber + 1).toString()
        return
      }

      Object.keys(this.data).forEach((key) => {
        if (typeof this.floorToEdit.data[key] !== 'undefined') {
          this.data[key] = this.floorToEdit.data[key].toString()
        }
      })
    },
  },
  components: {
    InputField,
    Button,
    ErrorText,
  },
  created() {
    this.setValuesOnLoad()
  },

}
</script>

<style lang="stylus" scoped>
  .NewFloorForm
    display block
    span
      display block
    .ErrorText
      padding-bottom 10px

  .Title
    modalTitle()

</style>
