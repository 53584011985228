import { cloneDeep } from 'lodash-es'

export const maxImageSize = 1600
export const minImageSize = 800

export const defaultExtraImageSizes = [
  // Example: { minSize: 200, prefix: 'small' },
]

export const emptyImageObject = () => {
  const imageObject = {
    base: {
      name: 'base',
      path: null,
      minSize: null,
    },
    sizes: [],
  }

  return cloneDeep(imageObject)
}

export const prepareImage = ({
  basePath,
  file,
  fileExtension,
  postFix,
  minSize,
  extraSizes = [],
}) => {
  const imageObject = emptyImageObject()
  const newFileExtension = fileExtension || file.name.split('.').pop()
  const imagePath = `${ basePath }${ new Date().getTime() }${ postFix ? `-${ postFix }` : '' }`

  // Fill out image object
  imageObject.base.path = `${ imagePath }.${ newFileExtension }`
  imageObject.base.minSize = minSize
  imageObject.sizes = extraSizes.map((size) => ({
    name: size.prefix,
    path: `${ imagePath }-${ size.prefix }.${ newFileExtension }`,
    minSize: size.minSize,
  }))

  return imageObject
}

export const rotateAndResizeImage = ({
  url,
}) => new Promise((resolve, reject) => {
  const img = new Image()
  img.crossOrigin = 'Anonymous'
  img.onload = () => {
    const canvas = document.createElement('canvas')

    const context = canvas.getContext('2d')

    const ratio = img.height / img.width
    canvas.height = img.height
    canvas.width = canvas.height * ratio

    context.save()
    context.translate(canvas.width / 2, canvas.height / 2) // Middle
    context.rotate(Math.PI / 2)
    // Draw the full original image, but on the new rotated canvas
    context.drawImage(img, 0, 0, img.width, img.height,
      -(canvas.height / 2), -(canvas.width / 2), canvas.height, canvas.width)
    context.restore()
    try {
      canvas.toBlob((blob) => resolve(blob), 'image/jpeg', 0.7)
    }
    catch (e) {
      reject(e)
    }
  }
  img.onerror = (e) => reject(e)
  img.src = url
})
