<template>
  <div class="FloorSelectItem">
    <!-- Floor -->
    <div
      class="FloorPart"
      @click="onFloorClick">
      <div class="CheckboxWrap">
        <div
          :class="{
            'IsSelected': floor.isSelected,
            'IsDisabled': isUnitInUse(floor),
          }"
          class="CheckBox">
          <CheckIcon class="CheckIcon" />
        </div>
      </div>
      <div class="InfoWrap">
        <span class="FloorTitle">{{ floor.getTitle() }}</span>
        <span v-if="floorApartments.length === 1">{{ mixWB('1_APARTMENT') }}</span>
        <span v-if="floorApartments.length > 1">
          {{ mixWB('X_APARTMENTS', [floorApartments.length]) }}
        </span>
      </div>
      <div
        v-if="!floor.bbrID"
        class="MenuWrap"
        @click.stop>
        <DotMenu
          :use-component="FloorSelectMenu"
          :dataToComponent="{
            canBeDeleted: isUnitAllowedToBeDeleted(floor),
          }"
          position="normal"
          @menu-click="onMenuClick" />
      </div>
    </div>

    <!-- Apartments -->
    <div
      v-if="floorApartments.length"
      class="ApartmentList">
      <ApartmentSelectItem
        v-for="apartment in floorApartments"
        :key="apartment.id"
        :apartment="apartment"
        :isUnitInUse="isUnitInUse" />
    </div>
  </div>
</template>

<script>
import CheckIcon from '@/assets/svg/check.svg?inline'
import ApartmentSelectItem from '@/components/ScreeningItems/ApartmentSelectItem.vue'
import DotMenu from '@/components/DotMenu/DotMenu.vue'
import FloorSelectMenu from '@/components/ScreeningItems/Menus/FloorSelectMenu.vue'
import EventBus from '@/EventBus'

export default {
  name: 'FloorSelectItem',
  props: {
    floor: {
      type: Object,
      required: true,
    },
    apartments: {
      type: Array,
      required: true,
    },
    isUnitAllowedToBeDeleted: {
      type: Function,
      required: true,
    },
    isUnitInUse: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      FloorSelectMenu,
    }
  },
  computed: {
    floorApartments() {
      return this.apartments.filter(
        (x) => x.data.floorID === this.floor.id,
      )
    },
  },
  methods: {
    onFloorClick() {
      if (this.floor.isSelected && this.isUnitInUse(this.floor)) {
        return
      }
      EventBus.$emit('floor-click', this.floor.id)
    },
    onMenuClick(value) {
      if (value === 'edit-floor') {
        EventBus.$emit('floor-edit-click', this.floor.id)
      }
      if (value === 'delete-floor') {
        EventBus.$emit('floor-delete-click', this.floor.id)
      }
    },
  },
  components: {
    CheckIcon,
    ApartmentSelectItem,
    DotMenu,
  },
}
</script>

<style lang="stylus" scoped>
  .FloorSelectItem
    padding 10px 0 10px 0px
    border-bottom 1px solid $color_grey_lighter
    &:last-child
      border-bottom none

  .FloorPart
    display flex
    cursor pointer
    align-items: center;
    .CheckboxWrap
      flex-center-children()
      .CheckBox
        box(26px)
        padding 2px
        margin-right 10px
        background-color #fff
        border 1px solid #777
        .CheckIcon
          display none
        &.IsSelected
          border 1px solid $color_primary
          .CheckIcon
            display block
            fill $color_primary
        &.IsDisabled
          border 1px solid $color_grey_light
          .CheckIcon
            fill $color_grey
    .InfoWrap
      flex-grow 2
      display flex
      flex-direction column
      justify-content center
      height 26px
      padding: 15px 10px 15px 0px;
      span
        display block
        font-size 0.875rem
      .FloorTitle
        font-size 1rem
        text-transform uppercase
        font-weight bold
    .MenuWrap
      width 50px
      flex-center-children()

  .ApartmentList
    margin-left 15px
    padding-top 10px
</style>
